import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Card, Form, Button } from "banafo-design-system";
import validateSchema from "src/features/auth/util/validateSchema";
import { recoveryChangePassword, recoveryPassInputChanged } from "src/features/auth/store/actions/auth/recovery";
import './ChangePasswordPage.scss';
import GeneralError from "src/features/auth/GeneralError";
import createAjvWithCustomFormats from 'src/shared/ajvCustomFormats';
const ajv = new createAjvWithCustomFormats({ allErrors: true, jsonPointers: true, $data: true });
require('ajv-errors')(ajv /*, {singleError: true} */);
const schema = {
    type: 'object',
    required: ["password", "passConf"],
    properties: {
        "password": {
            "type": "string",
            format: "password",
            errorMessage: {
                format: 'errors:ThePassword.does.not.match_'
            }
        },
        "passConf": {
            "type": "string",
            const: { $data: '1/password' },
            errorMessage: {
                const: 'errors:Password.and.confirm.password.do.not.match_',
            }
        }
    }
};
function ChangePasswordPage() {
    const { recoveryToken } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [registerInput, setRegisterInput] = useState({
        password: '',
        passConf: ''
    });
    const [errors, setErrors] = useState({});
    function handleChange(e) {
        const { name, value } = e.target;
        setRegisterInput(Object.assign(Object.assign({}, registerInput), { [name]: value }));
        dispatch(recoveryPassInputChanged(name));
    }
    const formSubmitted = e => {
        e.preventDefault();
        const { hasErrors, errors } = validateSchema(ajv, schema, registerInput, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        dispatch(recoveryChangePassword(recoveryToken, registerInput.password, registerInput.passConf));
    };
    return (_jsx("section", { className: "change-password", children: _jsx(Container, { fluid: 'xl', className: 'pt-300 pb-800', children: _jsx(Row, { children: _jsx(Col, { xs: 12, md: { span: 6, offset: 3 }, lg: { span: 6, offset: 6 }, xl: { span: 4, offset: 6 }, xxl: { span: 3, offset: 6 }, children: _jsx(Card, { className: "px-lg-300 py-lg-400 border-0", children: _jsx(Card.Body, { children: _jsxs(Form, { children: [_jsx("h2", { className: "text-center mb-200", children: t('Choose.your.new.password_') }), _jsx(Row, { className: "justify-content-center", children: _jsx(Col, { lg: 8, children: _jsx("p", { className: "mb-200 text-center", children: t('Enter.your.password.and.retype.it.again.to.confirm_') }) }) }), _jsx(GeneralError, { as: "h6" }), _jsxs(Form.Group, { controlId: "formPassword", className: 'mb-50', children: [_jsx(Form.Control, { placeholder: t("New.password_"), name: "password", type: 'password', onChange: e => handleChange(e), value: registerInput.password, isInvalid: !!errors.password }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.password })] }), _jsxs(Form.Group, { controlId: "formPassConf", className: 'mb-50', children: [_jsx(Form.Control, { placeholder: t("Confirm.password_"), name: "passConf", type: 'password', onChange: e => handleChange(e), value: registerInput.passConf, isInvalid: !!errors.passConf }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.passConf })] }), _jsx(Row, { children: _jsx(Col, { xs: 5, className: "m-auto", children: _jsx(Button, { variant: "dark", type: "submit", className: "mt-250 text-uppercase", onClick: formSubmitted, children: t('Change_') }) }) })] }) }) }) }) }) }) }));
}
export default ChangePasswordPage;
